import { basicPageProps } from '@/utils/isServer';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';

const ContentHeader = dynamic(() => import('@components/ContentHeader'), {
  loading: () => <p>Loading</p>,
});

const CruisePorts = dynamic(() => import('@components/CruisePorts'), {
  loading: () => <p>Loading</p>,
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { airports } = await basicPageProps(ctx);

  return {
    props: {
      airports,
    },
  };
};

function PremierParkingUsa() {
  return (
    <div>
      <Head>
        <meta
          name="description"
          content="Purchase Premier Parking USA cheap park deals for cruise and airport parking. Guaranteed the lowest rates."
        />
        <title>Cheap Airport Parking | Premier Parking USA</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ContentHeader btitle="Premier Parking USA" />
      <div className="p-4 md:p-4 items-center justify-center md:grid md:grid-cols-12">
        <div className="md:col-span-2"></div>
        <div className="md:col-span-8">
          <p className="mt-8 md:mt-12 text-sm md:text-base text-left">
            We purchased Premier Parking USA{' '}
            <span className="text-onair-orange">www.premierparkingusa.com</span>{' '}
            to make it simple and affordable for everyone to park near the
            airport or cruise port. Our company is not affiliated with the
            company Premier Parking USA. The online assets or markets listed on
            this page were purchased by our company.
          </p>
          <p className="mt-4 mb-8 md:mb-12 text-sm md:text-base text-left">
            Check out the latest parking deals here. Click on your local airport
            or cruise port for more information about our cheap parking
            offerings.
          </p>
        </div>
        <div className="md:col-span-2"></div>
      </div>
      <CruisePorts />
    </div>
  );
}

export default PremierParkingUsa;
